<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'daspsCrud'"
          crudName="DASP"
          newText="Lançar DASP"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="false"
          :enableDelete="false"
          :enableDownload="true"
          :enableView="true"
          :enableReset="false"
          :enableDouble="true"
          :dataForm="dataForm"
          :customFilters="customFilters"
          @filtered_ds="filtered_ds"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      customFilters: [
        {
          name: "created_at_f",
          type: "calendar",
          call: "filtered_ds",
        },
      ],
      created_at: "",
      table: "daspsTable",
      showMessageImport: false,
      daspsCrud: {},
      pages: [],
      url: "",
      columns: ["id", "collaborator", "bill_center", "dasp_number", "status", "created_at_f", "actions"],
      tableData: ["id", "collaborator", "bill_center", "dasp_number", "status", "created_at_f"],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["collaborator", "bill_center", "status"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          collaborator: "Colaborador",
          bill_center: "Centros de custo",
          dasp_number: "Número",
          created_at_f: "Data",
        },
        sortable: ["id"],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status: [
            {
              id: 0,
              text: "Rejeitado",
            },
            {
              id: 1,
              text: "Aguardando aprovação",
            },
            {
              id: 2,
              text: "Aguardando cotação",
            },
            {
              id: 3,
              text: "Aguardando compra",
            },
            {
              id: 4,
              text: "Aprovada e finalizada",
            },
          ]
        },
        templates: {
          collaborator: function (h, row, index) {
            return row.user_collaborator ? row.user_collaborator.name : "Não informado";
          },
          bill_center: function (h, row, index) {
            let bill_center = "";

            row.bill_center_dasp.forEach((bc, index) => {
              bill_center += bc.bill_center.name + ((index+1) < row.bill_center_dasp.length ? ", " : "");
            });

            return bill_center;
          },
          status: function (h, row, index) {
            switch (row.status) {
              case 0:
                return (
                  <h6 class="text-center">
                    <span class="badge badge-danger">Rejeitado</span>
                  </h6>
                );
              case 1:
                return (
                  <h6 class="text-center">
                    <span class="badge badge-primary">
                      Aguardando Aprovação
                    </span>
                  </h6>
                );
              case 2:
                return (
                  <h6 class="text-center">
                    <span class="badge badge-warning">Aguardando Cotação</span>
                  </h6>
                );
              case 3:
                return (
                  <h6 class="text-center">
                    <span class="badge badge-info">Aguardando Compra</span>
                  </h6>
                );
              case 4:
                return (
                  <h6 class="text-center">
                    <span class="badge badge-success">
                      Aprovada e Finalizada
                    </span>
                  </h6>
                );
            }
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.created_at != null &&
            this.$parent.$parent.$parent.created_at != ""
          ) {
            requestData.params.created_at =
              this.$parent.$parent.$parent.created_at;
          }

          requestData.params.query = "";
          requestData.params.with = ["user_collaborator", "bill_center_dasp"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "dasps/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    filtered_ds(date) {
      this.created_at = date;
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    importFile() {
      const self = this;
      let api = self.$store.state.api + "import-users";

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.showMessageImport = true;
          self.$refs.usersCrud.$refs.table.refresh();
        })
        .catch((error) => {
          self.$message(
            "Ops",
            "Houve um erro durante a importação da planilha, verifique a planilha e tente novamente.",
            "error"
          );
        });
    },
    query: function (query) {
      let columns = {
        id: "id",
        collaborator: "user_collaborator[name]",
        bill_center: "billsCenter[name]",
        status: "status",
      };

      let filters = "&orderByDesc=id";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>