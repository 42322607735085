import Crud from '../views/ReimbursementSolicitation/Crud'
import Entity from '../views/ReimbursementSolicitation/Add'
import Entity1 from '../views/ReimbursementSolicitation/Edit'
import View from '../views/ReimbursementSolicitation/View'

let routes = {
    path: '/reimbursement_solicitations',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/reimbursement_solicitations/add',
            name: 'NewReimbursementSolicitation',
            component: Entity,
            meta: {
                pageTitle: 'Solicitação de Reembolso',
                breadcrumb: [
                    {
                        text: 'Solicitação de Reembolso',
                        active: false,
                        to: '/reimbursement_solicitations',
                    },
                    {
                        text: 'Adicionar Solicitação de Reembolso',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/reimbursement_solicitations/edit/:id?',
            name: 'EditReimbursementSolicitation',
            component: Entity1,
            meta: {
                pageTitle: 'Solicitação de Reembolso',
                breadcrumb: [
                    {
                        text: 'Solicitação de Reembolso',
                        active: false,
                        to: '/reimbursement_solicitations',
                    },
                    {
                        text: 'Editar Solicitação de Reembolso',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/reimbursement_solicitations/view/:id?',
            name: 'ViewReimbursementSolicitation',
            component: View,
            meta: {
                pageTitle: 'Solicitação de Reembolso',
                breadcrumb: [
                    {
                        text: 'Solicitação de Reembolso',
                        active: false,
                        to: '/reimbursement_solicitations',
                    },
                    {
                        text: 'Visualizar Solicitação de Reembolso',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/reimbursement_solicitations',
            name: 'list-reimbursement-solicitations',
            component: Crud,
            meta: {
                pageTitle: '',
                breadcrumb: [
                    {
                        text: 'Solicitação de Reembolso',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
