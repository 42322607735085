<template>
  <div v-if="loading_status">
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body p-1 pb-1">
              <div class="row">
                <div class="col-md-9 bold fs-16">{{ call.title }}</div>
                <div
                  class="col-md-3 d-flex justify-content-end"
                  v-if="$store.state.user.perfil_id != 10"
                >
                  <button
                    style="margin-right: 8px"
                    class="btn btn-sm btn-primary"
                    data-toggle="modal"
                    data-target="#alterResponsible"
                    v-if="call.dev_acting != 0 && call.status != 9"
                  >
                    Alterar Responsável
                  </button>
                  <button
                    style="margin-right: 8px"
                    class="btn btn-sm btn-primary"
                    data-toggle="modal"
                    data-target="#alterStatus"
                  >
                    Alterar Status
                  </button>
                  <div class="status">
                    <span :class="'mr-1 badge badge-secondary'"></span>
                    <button
                      class="btn btn-sm btn-danger mr-1"
                      data-toggle="modal"
                      data-target="#deleteModal"
                      v-if="call.status != 9"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-primary"
                      @click="getTicket()"
                      v-if="call.dev_acting == 0 && call.status != 9"
                    >
                      Assumir Chamado
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card-footer" style="background: #ddd !important">
            <div class="col-md-12 text-center">
              <h5 class="mb-2 ml-1">Progresso Total</h5>
            </div>
            <div class="row d-flex justify-content-center align-items-top">
              <div
                class="col-md-3 p-0 card text-center"
                v-if="call.status >= 0"
              >
                <div class="card-header pt-1 pb-1 text-light bg-secondary">
                  Chamado Aberto<br />({{ call.data_error_f }})
                </div>
                <div class="card-body mt-1 text-left font-low">
                  {{ call.description_error }}
                </div>
              </div>
              <div
                class="col-md-4 row"
                v-for="progress in ticketProgress"
                :key="progress.id"
              >
                <div
                  class="col-md-3 d-flex align-items-center justify-content-center"
                  style="min-height: 100%"
                >
                  <i
                    class="fas fa-arrow-right"
                    style="font-size: 30px; max-height: 35px"
                  ></i>
                </div>
                <div class="col-md-9 p-0 card text-center">
                  <div
                    :class="
                      'card-header pt-1 pb-1 text-light bg-' + progress.class
                    "
                  >
                    {{ progress.type }} ({{
                      progress ? progress.created_at_f : ""
                    }})
                  </div>
                  <div
                    class="card-body mt-1 text-left font-low"
                    v-html="progress.text"
                  ></div>
                </div>
              </div>
              <div class="col-md-4 row" v-if="call.status === 9">
                <div
                  class="col-md-3 d-flex align-items-center justify-content-center"
                  style="min-height: 100%"
                >
                  <i
                    class="fas fa-arrow-right"
                    style="font-size: 30px; max-height: 35px"
                  ></i>
                </div>
                <div class="col-md-9 p-0 card text-center">
                  <div class="card-header pt-1 pb-1 text-light bg-danger">
                    Cancelado ({{ cancel ? cancel.created_at_f : "" }})
                  </div>
                  <div
                    class="card-body mt-1 text-left font-low"
                    v-html="cancel.description"
                  ></div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-7 col-lg-8 col-xl-9 col-12">
              <div
                class="card"
                :style="call.status_calls.badge_description_next"
              >
                <div class="card-body">
                  <div class="row">
                    <div
                      class="d-flex justify-content-between flex-column col-xl-7 col-21"
                    >
                      <div class="d-flex justify-content-start">
                        <span
                          class="b-avatar badge-light-danger rounded"
                          :style="
                            userDataFullscreen
                              ? 'width: 104px; height: 104px'
                              : 'width: 40px; height: 40px'
                          "
                          ><span class="b-avatar-img"
                            ><img
                              src="@/assets/images/avatars/default.png"
                              alt="avatar" /></span
                        ></span>
                        <div class="d-flex flex-column ml-1">
                          <div :class="userDataFullscreen ? 'mb-1' : ''">
                            <h4 class="mb-0">
                              <span>{{
                                call.user_model
                                  ? call.user_model.name
                                  : "Não informado"
                              }}</span>
                            </h4>
                            <span class="card-text" v-if="call.user_model"
                              ><a :mailto="call.user_model.email">{{
                                call.user_model.email
                              }}</a></span
                            >
                            <br v-if="userDataFullscreen" />
                            <br v-if="userDataFullscreen" />
                            <span
                              class="badge mr-1 fs-14"
                              :class="call.status_calls.badge_description"
                              v-if="userDataFullscreen"
                            >
                              {{ call.status_calls.description }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <button
                            class="btn btn-sm btn-block btn-add"
                            data-toggle="modal"
                            data-target="#changePriority"
                            v-if="call.status != 9"
                          >
                            <i class="fas fa-asterisk"></i> Alterar Prioridade
                          </button>
                        </div>
                        <div class="col-md-4">
                          <button
                            class="btn btn-sm btn-block btn-add"
                            data-toggle="modal"
                            data-target="#changeDeadline"
                            v-if="
                              $store.state.user.perfil_id != 10 &&
                              call.status != 9
                            "
                          >
                            <i class="far fa-calendar-check"></i> Alterar Prazo
                          </button>
                        </div>
                      </div>
                      <div
                        class="d-flex align-items-center"
                        v-if="userDataFullscreen"
                      >
                        <div class="d-flex align-items-center mr-2">
                          <span class="b-avatar badge-light-primary rounded"
                            ><i class="far fa-clock" style="font-size: 18px"></i
                          ></span>
                          <div class="ml-1">
                            <h5 class="mb-0">{{ call.diff_in_hours }}</h5>
                            <small>Tempo desde a abertura</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-5 col-12" v-if="userDataFullscreen">
                      <table class="mt-2 mt-xl-0 w-100">
                        <tr>
                          <th class="pb-50">
                            <i class="fas fa-sitemap mr-75"></i>
                            <span class="font-weight-bold">Sistema</span>
                          </th>
                          <td class="pb-50">
                            {{ call.project ? call.project.name : "" }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-50">
                            <i class="fas fa-building mr-75"></i>
                            <span class="font-weight-bold">Cliente</span>
                          </th>
                          <td class="pb-50 text-capitalize">
                            {{ call.client ? call.client.company_name : "" }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-50">
                            <i class="fas fa-user mr-75"></i>
                            <span class="font-weight-bold"
                              >Contato do Cliente</span
                            >
                          </th>
                          <td class="pb-50 text-capitalize">
                            {{
                              call.client_contact
                                ? call.client_contact.name
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-50">
                            <i class="fas fa-i-cursor"></i>
                            <span class="font-weight-bold ml-1"
                              >Tipo do Chamado</span
                            >
                          </th>
                          <td class="pb-50 text-capitalize">
                            {{ call.type_call ? call.type_call.name : "" }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-50">
                            <i class="far fa-calendar-check"></i>
                            <span class="font-weight-bold ml-1">Prazo</span>
                          </th>
                          <td class="pb-50 text-capitalize">
                            {{
                              call.deadline_f
                                ? call.deadline_f
                                : "Não informado"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pb-50">
                            <i class="fas fa-asterisk"></i>
                            <span class="font-weight-bold ml-1"
                              >Prioridade</span
                            >
                          </th>
                          <td class="pb-50 text-capitalize">
                            {{ call.priority }}
                          </td>
                        </tr>
                      </table>

                      <div class="d-flex align-items-center mt-3">
                        <span
                          class="b-avatar rounded"
                          :class="
                            call.sla.proximity == 1
                              ? 'badge-light-success'
                              : call.sla.proximity == 2
                              ? 'badge-light-warning'
                              : 'badge-light-danger'
                          "
                          ><i class="far fa-clock" style="font-size: 18px"></i
                        ></span>
                        <div class="ml-1">
                          <h5 class="mb-0">{{ call.sla.text }}</h5>
                          <small>SLA</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xl-3 col-12">
              <div class="card border-primary">
                <div
                  class="card-header d-flex justify-content-between align-items-center pt-75 pb-25"
                  :class="!userDataFullscreen ? 'mb-2' : ''"
                >
                  <h5 class="mb-0" style="font-weight: bold; font-size: 20px">
                    #{{ call.id.toString().padStart(6, "0") }}
                  </h5>
                  <h2 style="color: blue; margin-top: 5px; margin-right: -35px">
                    <span id="timer"></span>
                    <i
                      class="fas fa-clock fa-spin ml-1 mr-2"
                      style="font-size: 24px; color: blue; margin-bottom: -5px"
                    ></i>
                  </h2>

                  <small class="text-muted w-100">Nº do Chamado</small>
                </div>
                <div class="card-body" v-if="userDataFullscreen">
                  <ul class="list-unstyled my-1">
                    <li>
                      <span class="align-middle">
                        <h5 style="font-weight: bold; font-size: 14px">
                          {{ call.created_at_f }}
                        </h5>
                        <small class="text-muted"
                          >Data de Abertura do Chamado</small
                        >
                      </span>
                    </li>
                    <li class="mt-1">
                      <span class="align-middle">
                        <h5
                          class="mb-0"
                          style="
                            font-weight: bold;
                            font-size: 18px;
                            margin-bottom: -15px;
                          "
                        >
                          {{ call.dev ? call.dev.name : "Não atribuído" }}
                        </h5>
                        <small class="text-muted w-100"
                          >Desenvolvedor Responsável</small
                        >
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Informações Adicionais</h5>
                  <div class="row">
                    <div class="col-md-4">
                      <label>Usuário do Sistema</label>
                      <input
                        v-model="call.user"
                        id="name"
                        class="form-control"
                        type="text"
                        disabled
                      />
                    </div>
                    <div class="col-md-3">
                      <label>Módulo</label>
                      <input
                        class="form-control"
                        type="text"
                        id="name"
                        placeholder="Ex. Dashboard, Usuário, etc"
                        v-model="call.method_error"
                        disabled
                      />
                    </div>
                    <div class="col-md-3">
                      <label>Requisição</label>
                      <input
                        class="form-control"
                        type="text"
                        id="name"
                        placeholder="Ex. Criar, Editar, etc..."
                        v-model="call.request_error"
                        disabled
                      />
                    </div>
                    <div class="col-md-2">
                      <label>Data e Hora do Erro</label>
                      <input
                        v-model="call.data_error"
                        class="form-control"
                        type="datetime-local"
                        disabled
                      />
                    </div>
                    <div class="col-md-12 mt-1">
                      <label>Descrição</label>
                      <ckeditor
                        v-model="call.description_error"
                        :editor="editor"
                        :config="editorDisabledConfig"
                        disabled
                      />
                    </div>
                    <div class="col-md-12"><hr /></div>
                    <div class="col-md-12" v-if="call.json">
                      <label>JSON</label>
                      <json-viewer
                        :value="call.json"
                        :expand-depth="5"
                        copyable
                        boxed
                        sort
                      ></json-viewer>
                    </div>
                    <div class="col-md-12 mt-1" v-if="call.url_system">
                      <label>URL</label>
                      <input
                        v-model="call.url_system"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Interações -->
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Interações</h5>
                  <div class="row">
                    <div class="col-md-12">
                      <label>Nova Interação</label>
                      <ckeditor
                        v-model="interaction.description"
                        :editor="editor"
                        :config="editorConfig"
                      />
                    </div>
                    <div class="col-md-12 text-right mt-1">
                      <button
                        class="btn btn-primary"
                        @click="saveInteraction()"
                      >
                        <i class="fas fa-save"></i> Salvar
                      </button>
                    </div>
                    <div class="col-md-12 mt-3">
                      <div
                        class="row"
                        v-for="interact in call_interactions"
                        :key="interact.id"
                      >
                        <div class="col-md-12">
                          <div
                            class="card"
                            :class="
                              interact.actor == 0
                                ? user_logged != interact.user_id &&
                                  interact.actor == 0
                                  ? 'gray-50'
                                  : 'gray-100'
                                : 'blue'
                            "
                          >
                            <div class="card-body">
                              <h5
                                class="card-title"
                                :class="
                                  user_logged != interact.user_id
                                    ? 'text-right'
                                    : ''
                                "
                                style="
                                  font-size: 14px;
                                  font-style: italic;
                                  font-weight: bold;
                                "
                              >
                                {{ interact.user.name }} em
                                {{ interact.created_at_f }}
                              </h5>
                              <p class="card-text">
                                <span v-html="interact.description"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de alterar responsável -->
    <div
      class="modal fade"
      id="alterResponsible"
      tabindex="-1"
      role="dialog"
      aria-labelledby="title"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="title">Alterar Responsável</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <div class="col-md-12">
              <label>Selecione Responsável</label>
              <v-select
                v-model="call.dev_acting"
                :options="allusers"
                :reduce="(allusers) => allusers.id"
                :label="'name'"
              ></v-select>
            </div>
            <br />
            <label>Descreva o Motivo da alteração</label>
            <ckeditor v-model="text_responsable" :editor="editor" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="saveAlterResponsible()"
            >
              Alterar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de alterar status -->
    <div
      class="modal fade"
      id="alterStatus"
      tabindex="-1"
      role="dialog"
      aria-labelledby="title"
      aria-hidden="true"
      v-if="loading_status"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="title">Alterar Status</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <div class="col-md-12">
              <label>Selecione o novo status</label>
              <v-select
                v-model="call_status_id"
                :options="allstatus"
                :reduce="(allstatus) => allstatus.status"
                :label="'description'"
              ></v-select>
            </div>
            <br />
            <label>Descreva o Motivo da alteração</label>
            <ckeditor v-model="text" :editor="editor" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="saveAlterStatus()"
            >
              Alterar
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de deletar chamado -->

    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="title"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="title">Cancelar Chamado</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <label>Descreva o motivo do cancelamento</label>
            <ckeditor v-model="delete_text" :editor="editor" />
            <div class="row mt-1">
              <button
                class="btn btn-sm offset-1 col-md-3"
                style="border: 2px solid #4b56eb"
                @click="sugestion(1)"
              >
                Chamado em duplicidade
              </button>
              <button
                class="btn btn-sm offset-1 col-md-6"
                style="border: 2px solid #4b56eb"
                @click="sugestion(2)"
              >
                Problema corrigido na próxima versão
              </button>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleleTicket()"
            >
              Cancelar Chamado
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal de alterar prazo -->

    <div
      class="modal fade"
      id="changeDeadline"
      tabindex="-1"
      role="dialog"
      aria-labelledby="title"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="title">Alterar Prazo</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <label>Qual o prazo para este chamado?</label>
            <input
              type="datetime-local"
              v-model="call.deadline"
              class="form-control"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="alterDeadline()"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal de alterar prioridade -->

    <div
      class="modal fade"
      id="changePriority"
      tabindex="-1"
      role="dialog"
      aria-labelledby="title"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="title">Alterar Prazo</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <label>Qual a prioridade para este chamado?</label>
            <select v-model="call.priority" class="form-control">
              <option value="🔵">🔵 Ultra baixa prioridade</option>
              <option value="🟢">🟢 Baixa prioridade</option>
              <option value="🟡">🟡 Média prioridade</option>
              <option value="🔴">🔴 Alta prioridade</option>
            </select>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="alterPriority()"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import JsonViewer from "vue-json-viewer";

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    // Note that your request may look different. It is up to you and your editor
    // integration to choose the right communication channel. This example uses
    // a POST request with JSON as a data structure but your configuration
    // could be different.
    xhr.open(
      "POST",
      "https://api.intranet-dev.it4d.com.br/api/v1/images/upload",
      true
    );
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url,
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();

    data.append("upload", file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
    return new MyUploadAdapter(loader);
  };
}

export default {
  name: "imageUpload",
  jsonData: {
    total: 25,
    limit: 10,
    skip: 0,
    links: {
      previous: undefined,
      next: function () {},
    },
  },
  data() {
    return {
      userDataFullscreen: true,
      call: {
        id: 0,
        status: 0,
      },
      interaction: {
        description: "",
      },
      status: {
        class: "secondary",
        text: "Carregando...",
      },
      allstatus: [],
      allusers: [],
      cancel: [],
      call_interactions: [],
      delete_text: "",
      call_status_id: "",
      text: "",
      text_responsable: "",
      ticketProgress: [],
      editor: ClassicEditor,
      editorConfig: {
        width: "800px",
        height: "400px",
        extraPlugins: [MyUploadAdapterPlugin],
      },
      editorDisabledConfig: {
        toolbar: {
          items: [],
        },
      },
      loading_status: false,
      user_logged: {},
    };
  },
  computed: {},
  methods: {
    saveInteraction() {
      const self = this;
      const api = self.$store.state.api + "call_interactions/";

      self.interaction.call_id = self.call.id;
      self.interaction.user_id = self.$store.state.user.id;

      axios
        .post(api, self.interaction)
        .then(() => {
          self.$message("Sucesso", `Interação salva com sucesso`, "success");

          self.interaction.description = "";
          self.getCall(self.call.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    deleleTicket: function () {
      const self = this;
      const api = self.$store.state.api + "cancel_reasons/";

      let data = {};
      data.description = self.delete_text;
      data.ticket_id = self.$route.params.id;
      data.user_id = self.$store.state.user.id;

      axios
        .post(api, data)
        .then(() => {
          self.$message("Sucesso", `Chamado cancelado com sucesso`, "success");

          self.delete_text = "";
          self.getCall(data.ticket_id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCall: function (id) {
      const self = this;
      const api =
        self.$store.state.api +
        "calls/" +
        id +
        "?with[]=project&with[]=client&with[]=client_contact&with[]=status_calls&with[]=user_model&with[]=dev&with[]=type_call";

      axios
        .get(api)
        .then((response) => {
          self.call = response.data.data[0];
          if (self.call.type_call_id == null) {
            self.call.type_call_id = 1;
          }
          self.getTicketProgress(id);
          self.call.dev_acting_old = self.call.dev_acting;
          self.status.text = self.call.status_calls.description;
          self.status.next_class =
          self.call.status_calls.badge_description_next;
          self.status.next_text = self.call.status_calls.description_next;
          self.getInteractions();
          self.user_logged = self.$store.state.user.id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getInteractions() {
      const self = this;
      const api =
        self.$store.state.api +
        "call_interactions/?call_id=" +
        self.call.id +
        "&orderByDesc=id&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.call_interactions = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCancelReason: function (id) {
      const self = this;
      const api = self.$store.state.api + "cancel_reasons?ticket_id=" + id;

      axios
        .get(api)
        .then((response) => {
          self.cancel = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTicketProgress: function (id) {
      const self = this;
      const api =
        self.$store.state.api +
        "ticket_progresses?ticket_id=" +
        id +
        "&orderByAsc=next_status&with[]=user_fineshed";

      axios
        .get(api)
        .then((response) => {
          self.ticketProgress = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTicket: function () {
      const self = this;
      const api = self.$store.state.api + "get_ticket/";
      let data = {};

      data.ticket = self.$route.params.id;
      data.user = self.$store.state.user.id;

      axios
        .post(api, data)
        .then(() => {
          self.getCall(data.ticket);
          self.getTicketProgress(data.ticket);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAllStatus: function () {
      const self = this;
      const api = self.$store.state.api + "statusCall/";

      axios
        .get(api)
        .then((response) => {
          self.allstatus = response.data.data;
          self.loading_status = true;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    
    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users/?limit=9999"; // Garante que todos venham

      axios
        .get(api)
        .then((response) => {
          self.allusers = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    saveTicketProgress: function () {
      const self = this;
      const api = self.$store.state.api + "ticket_progresses/";
      let data = {};
      data.text = self.text;
      data.status = self.call.status;
      data.ticket = self.$route.params.id;

      axios
        .post(api, data)
        .then(() => {
          self.$message("Sucesso", `Chamado avançado com sucesso`, "success");

          self.text = "";
          self.getTicketProgress(data.ticket);
          self.getCall(data.ticket);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    saveAlterStatus: function () {
      const self = this;
      let data = {};
      const api = self.$store.state.api + "logStatusCall";
      if (self.call.status == self.call_status_id) {
        self.$message(
          "Erro",
          "Status escolhido é o mesmo do chamado!",
          "error"
        );
        return false;
      }

      data.description = self.text;
      data.description_2 = self.text;
      data.id_status_old = self.call.status;
      data.id_status_new = self.call_status_id;
      data.id_calls = self.call.id;

      axios
        .post(api, data)
        .then(() => {
          self.alterStatus();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    saveAlterResponsible: function () {
      const self = this;
      let data = {};
      const api = self.$store.state.api + "logAlterResponsible";

      if (self.call.dev_acting == "" || self.call.dev_acting == 0) {
        self.$message("Erro", "Selecione um responsável!", "error");
        return false;
      }

      if (self.call.dev_acting == self.call.dev_acting_old) {
        self.$message(
          "Erro",
          "Responsável escolhido é o mesmo do chamado!",
          "error"
        );
        return false;
      }
      data.description = self.text_responsable;
      data.id_responsable_old = self.call.dev_acting_old;
      data.id_responsable_new = self.call.dev_acting;
      data.id_calls = self.call.id;

      axios
        .post(api, data)
        .then(() => {
          self.alterReponsable();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    alterPriority: function () {
      const self = this;
      const api = self.$store.state.api + "calls/" + self.call.id;

      axios
        .patch(api, { priority: self.call.priority })
        .then(() => {
          self.$message("Sucesso", `Prioridade alterada!`, "success");
          self.getCall(self.call.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    alterDeadline: function () {
      const self = this;
      const api = self.$store.state.api + "calls/" + self.call.id;

      axios
        .patch(api, { deadline: self.call.deadline })
        .then(() => {
          self.$message("Sucesso", `Prazo alterado!`, "success");
          self.getCall(self.call.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    alterStatus: function () {
      const self = this;
      const api = self.$store.state.api + "calls/" + self.call.id;
      self.call.status = self.call_status_id;

      delete self.call.closed_at;

      axios
        .put(api, self.call)
        .then(() => {
          self.$message("Sucesso", `Status alterado!`, "success");
          self.getCall(self.call.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    alterReponsable: function () {
      const self = this;
      const api = self.$store.state.api + "calls/" + self.call.id;
      axios
        .put(api, self.call)
        .then(() => {
          self.$message("Sucesso", `Responsável alterado!`, "success");
          self.getCall(self.call.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    sugestion: function (id) {
      const self = this;

      switch (id) {
        case 1:
          self.delete_text = "Chamado em duplicidade";
          break;
        case 2:
          self.delete_text = "Problema corrigido na próxima versão";
          break;
      }
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;
    self.getAllStatus();
    self.getUsers();

    if (id) {
      self.getCall(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
    JsonViewer,
  },
};
</script>
<style>
.ck-editor__editable {
  min-height: 150px;
  color: black;
}

.gray-50 {
  background-color: #f3f3f3 !important;
  margin-left: 70px;
}

.gray-100 {
  background-color: #d8d8d8 !important;
  margin-right: 70px;
}

.blue {
  background-color: #daecff !important;
  text-align: center !important;
}
</style>