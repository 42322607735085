<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card p-1 pb-2">
            <div class="row">
              <div class="col-md-4">
                <h5>Dasp Nº {{ dasp.dasp_number }}</h5>
                <h6>Data: {{ dasp.created_at_f }}</h6>
              </div>
              <div class="col-md-8 row justify-content-end">
                <div>
                  <span
                    class="badge"
                    :class="'badge-' + status[dasp.status].class"
                    >{{ status[dasp.status].name }}</span
                  >
                </div>
                <div>
                  <button
                    class="btn btn-add btn-crud ml-1 p-0"
                    data-toggle="modal"
                    data-target="#modalNotes"
                  >
                    <span class="notification-badge" v-if="notes.length">
                      {{ notes.length }}
                    </span>
                    <i class="fas fa-comment"></i>
                  </button>
                  <div
                    class="modal fade"
                    id="modalNotes"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modalNotesTitle"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div class="modal-content">
                        <div class="modal-header bg-primary">
                          <h5 class="modal-title text-light">Observações ({{ notes.length }})</h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="col-12" v-for="note in notes">
                              <b>
                                <h5>
                                  {{ note.user.name }} ({{ note.created_at_f }})
                                 </h5>
                              </b>
                              <h6>
                                {{ note.text }}
                              </h6>
                              <hr />
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <textarea
                            cols="30"
                            rows="4"
                            class="form-control"
                            v-model="text"
                          ></textarea>
                          <button
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Cancelar
                          </button>
                          <button
                            class="btn btn-primary"
                            data-dismiss="modal"
                            @click="addNote()"
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <label>Centro de Custo</label>
                <v-select
                  v-model="dasp.bill_center_id"
                  :options="billCenter"
                  :reduce="(billCenter) => billCenter.id"
                  :label="'number_name'"
                  multiple
                  disabled
                ></v-select>
              </div>
              <div class="col-md-12">
                <label>Colaborador</label>
                <v-select
                  v-model="dasp.collaborator"
                  :options="users"
                  :reduce="(users) => users.id"
                  :label="'name'"
                  disabled
                ></v-select>
              </div>
              <div class="col-md-6">
                <label>Destino desta aquisição</label>
                <v-select
                  v-model="dasp.destination"
                  :options="destination"
                  :reduce="(destination) => destination.id"
                  :label="'name'"
                   :disabled="
                      !(
                        [2, 3].includes(current_manager.action) &&
                        current_manager.user_id === $store.state.user.id
                      )
                  "
                ></v-select>
              </div>
              <div class="col-md-6">
                <label>Acordo com fornecedor</label>
                <v-select
                  v-model="dasp.agreement"
                  :options="agreement"
                  :reduce="(agreement) => agreement.id"
                  :label="'name'"
                   :disabled="
                    !(
                      [2, 3].includes(current_manager.action) &&
                      current_manager.user_id === $store.state.user.id
                    )
                  "
                ></v-select>
              </div>
              <div class="col-md-4">
                <label>Objeto da aquisição</label>
                <v-select
                  v-model="dasp.type"
                  :options="type"
                  :reduce="(type) => type.id"
                  :label="'name'"
                  :disabled="
                    !(
                      [2, 3].includes(current_manager.action) &&
                      current_manager.user_id === $store.state.user.id
                    )
                  "
                ></v-select>
              </div>
              <hr />
              <div
                class="col-md-4"
              >
              <!-- :class="dasp.term === 3 ? 'col-md-4' : 'col-md-8'" -->
                <label>Urgencia</label>
                <v-select
                  v-model="dasp.term"
                  :options="term"
                  :reduce="(term) => term.id"
                  :label="'name'"
                  :disabled="
                    !(
                      [2, 3].includes(current_manager.action) &&
                      current_manager.user_id === $store.state.user.id
                    )
                  "
                ></v-select>
              </div>
              <div class="col-md-2">
                <label>Valor Orçado</label>
                <input type="text" class="form-control" v-model="dasp.budget" v-money="moneyConfig" disabled>
              </div>
              <div class="col-md-2">
                <label>Valor Pago</label>
                <input type="text" class="form-control" v-model="dasp.paid" v-money="moneyConfig" disabled>
              </div>
              <!-- <div
                :class="dasp.term === 3 ? 'col-md-4' : 'd-none'"
                v-show="
                  [2, 3].includes(current_manager.action) &&
                  current_manager.user_id === $store.state.user.id
                "
              >
                <label>Especifique</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="dasp.term_especification"
                />
              </div> -->
              <div class="col-12 mt-2">
                <h4>Detalhes do produto/serviço</h4>
              </div>
              <div
                class="mt-1"
                style="width: 96%; margin: 0 2%; overflow-x: auto"
              >
                <table class="table table-bordered" style="width: 100%; overflow: hidden;">
                  <thead>
                    <tr class="text-center">
                      <th scope="col" style="min-width: 20%; max-width: 20%;">Descrição / <br> Valor&nbsp;Orçado&nbsp;(Total)</th>
                      <th scope="col" style="min-width: 20%; max-width: 20%;">Link / <br> Valor&nbsp;Pago&nbsp;(Total)</th>
                      <th scope="col" style="min-width: 20%; max-width: 20%;">Quantidade / <br> Prazo</th>
                      <th scope="col" style="min-width: 20%; max-width: 20%;">Fornecedor / <br> Data&nbsp;de&nbsp;compra</th>
                      <th scope="col" style="min-width: 20%; max-width: 20%;">Pagamento</th>
                    </tr>
                  </thead>
                  <tbody v-for="(product, index) in list" :key="index">
                    <tr>
                      <td>{{ product.name }}</td>
                      <td
                        style="
                          max-width: 200px;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                        :title="product.link"
                      >
                        <a :href="product.link" target="_blank">{{ product.link }}</a>
                      </td>
                      <td align="center">{{ product.quantity }}</td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="product.supplier"
                          :disabled="
                            [2, 3].includes(current_manager.action) &&
                            current_manager.user_id === $store.state.user.id
                              ? false
                              : true
                          "
                        />
                      </td>
                      <td rowspan="2">
                        <v-select
                          v-model="product.payment"
                          :options="payment"
                          :reduce="(payment) => payment.id"
                          :label="'name'"
                          
                          :disabled="
                            [2, 3].includes(current_manager.action) &&
                            current_manager.user_id === $store.state.user.id
                              ? false
                              : true
                          "
                        ></v-select>
                        <input
                          type="text"
                          class="form-control mt-1"
                          placeholder="Últimos digitos"
                          v-model="product.last_digits"
                          v-mask="'9999'"
                          v-if="product.payment === 1"
                          :disabled="
                            [2, 3].includes(current_manager.action) &&
                            current_manager.user_id === $store.state.user.id
                              ? false
                              : true
                          "
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="product.budget"
                          v-money="moneyConfig"
                          min="0"
                          :disabled="
                            [2, 3].includes(current_manager.action) &&
                            current_manager.user_id === $store.state.user.id
                              ? false
                              : true
                          "
                          @keypress="preventNegative"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="product.paid"
                          v-money="moneyConfig"
                          min="0"
                          :disabled="
                            [2, 3].includes(current_manager.action) &&
                            current_manager.user_id === $store.state.user.id
                              ? false
                              : true
                            "
                          @keypress="preventNegative"
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          class="form-control"
                          v-model="product.term"
                          :disabled="
                            [2, 3].includes(current_manager.action) &&
                            current_manager.user_id === $store.state.user.id
                              ? false
                              : true
                          "
                          :min="today"
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          class="form-control"
                          v-model="product.buy"
                          :disabled="
                            [2, 3].includes(current_manager.action) &&
                            current_manager.user_id === $store.state.user.id
                              ? false
                              : true
                          "
                          :min="today"
                        />
                      </td>
                    </tr>
                    <tr><td colspan="5"></td></tr>
                  </tbody>
                </table>
              </div>
              <div
                class="col-md-6 mt-2 d-flex justify-content-center"
                v-if="
                  current_manager.action === 1 &&
                  current_manager.user_id === $store.state.user.id
                "
              >
                <button class="btn btn-outline-success w-75" @click="approve">
                  Aprovar
                </button>
              </div>
              <div
                class="col-md-6 mt-2 d-flex justify-content-center"
                v-if="
                  current_manager.action === 1 &&
                  current_manager.user_id === $store.state.user.id
                "
              >
                <button class="btn btn-outline-danger w-75" @click="reject">
                  Rejeitar
                </button>
              </div>
              <div
                class="col-md-12 mt-2 d-flex justify-content-center"
                v-if="
                  current_manager.action === 3 &&
                  current_manager.user_id === $store.state.user.id
                "
              >
                <button class="btn btn-add w-100" @click="sign">Assinar</button>
              </div>
              <div class="col-md-12 text-right">
                <hr />
                <router-link to="/dasps" class="btn btn-back mr-1">
                  <i class="fa fa-arrow-left" />
                  Voltar
                </router-link>
                <a
                  class="btn btn-add"
                  @click="save"
                  v-if="
                    [2, 3].includes(current_manager.action) &&
                    current_manager.user_id === $store.state.user.id
                  "
                >
                  Salvar
                  <i class="fa fa-save" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      moneyConfig: {
        prefix: "R$ ",
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
      today: new Date().toISOString().split("T")[0],
      text: "",
      dasp: {},
      billCenter: [],
      users: [],
      products: [],
      list: [],
      notes: {},
      current_manager: [],
      destination: [
        {
          id: 1,
          name: "Patrimônio IT4D",
        },
        {
          id: 2,
          name: "Para o cliente previsto no contrato de fornecimento",
        },
        {
          id: 3,
          name: "Consumo",
        },
        {
          id: 4,
          name: "Revenda",
        },
      ],
      agreement: [
        {
          id: 1,
          name: "Apenas Pedido de Compra",
        },
        {
          id: 2,
          name: "Fornecimento Recorrente",
        },
      ],
      type: [
        {
          id: 1,
          name: "Serviços",
        },
        {
          id: 2,
          name: "Equipamentos e Materiais",
        },
      ],
      term: [
        {
          id: 1,
          name: "Imediato",
        },
        {
          id: 2,
          name: "Muito urgente",
        },
        {
          id: 3,
          name: "Urgente",
        },
        {
          id: 4,
          name: "Não urgente",
        },
      ],
      payment: [
        {
          id: 1,
          name: "Cartão de Crédito",
          active: true,
        },
        {
          id: 2,
          name: "Boleto",
          active: false,
        },
        {
          id: 3,
          name: "Pix",
          active: false,
        },
      ],
      status: [
        {
          name: "Rejeitado",
          class: "danger",
        },
        {
          name: "Aguardando aprovação",
          class: "primary",
        },
        {
          name: "Aguardando Cotação",
          class: "warning",
        },
        {
          name: "Aguardando Compra",
          class: "success",
        },
        {
          name: "Aprovada e Finalizada",
          class: "success",
        },
      ],
    };
  },
  computed: {},
  watch: {
    list: {
      handler() {
        const self = this;

        self.changeBudget();
        self.changePaid();
      },
      deep: true,
    },
  },
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      fd.append("budget",         self.dasp.budget          ? self.dasp.budget          : "");
      fd.append("paid",           self.dasp.paid            ? self.dasp.paid            : "");
      fd.append("bill_center_id", self.dasp.bill_center_id  ? self.dasp.bill_center_id  : "");
      fd.append("destination",    self.dasp.destination     ? self.dasp.destination     : "");
      fd.append("agreement",      self.dasp.agreement       ? self.dasp.agreement       : "");
      fd.append("collaborator",   self.dasp.collaborator    ? self.dasp.collaborator    : "");
      fd.append("term",           self.dasp.term            ? self.dasp.term            : "");
      fd.append("type",           self.dasp.type            ? self.dasp.type            : "");
      fd.append("status",         self.dasp.status          ? self.dasp.status          : "");

      fd.append("list", JSON.stringify(self.list));

      fd.append("_method", "PUT");

      return fd;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "dasps";

      api += "/" + self.dasp.id;

      if (!self.list.length) {
        this.$message(
          null,
          "Você precisa adicionar produtos/serviços",
          "error"
        );
        return;
      }

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/dasps");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getDasp: function (id) {
      const self = this;
      const api = self.$store.state.api + "dasps/" + id + "?with[]=bill_center_dasp";

      axios
        .get(api)
        .then((response) => {
          if (!response.data.data.length) {
            return this.$router.push("/dasps");
          }

          self.dasp = response.data.data[0];

          self.dasp.bill_center_id = [];
          let i = 0;
          self.dasp.bill_center_dasp.forEach(billCenter => {
            self.dasp.bill_center_id[i] = billCenter.bill_center_id;
            i++
          });


          self.getUserFlow(id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getDaspProducts: function (id) {
      const self = this;
      const api = self.$store.state.api + "dasp_products?dasp_id=" + id;

      axios
        .get(api)
        .then((response) => {
          self.list = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getBillCenter: function () {
      const self = this;
      const api = self.$store.state.api + "bill_centers?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.billCenter = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUsers: function () {
      const self = this;
      const api = self.$store.state.api + "users?paginated=false";

      self.$http
        .get(api)
        .then((response) => {
          self.users = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getUserFlow: function () {
      const self = this;
      const api =
        self.$store.state.api +
        "bill_center_flows?bill_center_id=" +
        self.dasp.bill_center_id +
        "&user_id=" +
        self.dasp.current_manager +
        "&action=" +
        self.dasp.status;

      axios
        .get(api)
        .then((response) => {
          self.current_manager = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    formatarData(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    approve: function () {
      const self = this;
      const api =
        self.$store.state.api +
        "dasps_approvement/" +
        self.dasp.id +
        "?approve=1&bill_center_id=" +
        self.dasp.bill_center_id;

      axios
        .post(api)
        .then(() => {
          self.$router.push("/dasps");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    reject: function () {
      const self = this;
      const api = self.$store.state.api + "dasps_approvement/" + self.dasp.id + "?approve=0&bill_center_id=" + self.dasp.bill_center_id;

      axios
        .post(api)
        .then(() => {
          self.$router.push("/dasps");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    sign: function () {
      const self = this;
      const api = self.$store.state.api + "dasps_sign/" + self.dasp.id;

      axios
        .post(api)
        .then(() => {
          self.$router.push("/dasps");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getNotes: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "dasp_notes?with[]=user&dasp_id=" + id;

      axios
        .get(api)
        .then((response) => {
          self.notes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    addNote: function () {
      const self = this;
      const api = self.$store.state.api + "dasp_notes";

      let fd = self.notesFD();

      self.text = "";

      axios
        .post(api, fd)
        .then(() => {
          self.getNotes(self.dasp.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    notesFD: function () {
      const self = this;
      let fd = new FormData();

      fd.append("user_id", self.$store.state.user.id);
      fd.append("dasp_id", self.dasp.id ? self.dasp.id : "");
      fd.append("text",    self.text ? self.text : "");

      return fd;
    },
    preventNegative: function(event){
      if (event.key === '-') {
        event.preventDefault();
      }
    },
    changeBudget() {
      const self = this;

      self.dasp.budget = 0;

      self.list.forEach((product) => {
        self.dasp.budget += (parseFloat(product.budget.slice(3).replace(/\./g, '').replace(/,/g, '.')));
      });

      self.dasp.budget = Math.round(self.dasp.budget  * 10000) / 100;
    },
    changePaid() {
      const self = this;

      self.dasp.paid = 0;

      self.list.forEach((product) => {
        self.dasp.paid += (parseFloat(product.paid.slice(3).replace(/\./g, '').replace(/,/g, '.')));
      });

      self.dasp.paid = Math.round(self.dasp.paid  * 10000) / 100;
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.getBillCenter();
    self.getUsers();

    if (id) {
      self.getDasp(id);
      self.getNotes(id);
      self.getDaspProducts(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.notification-badge {
  position: absolute;
  top: 1px;
  right: 8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.2em 0.5em;
  font-size: 0.75em;
  font-weight: bold;
  min-width: 1em;
  text-align: center;
  line-height: 1;
}

</style>