<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'home_officeCrud'"
          crudName="Escalas"
          newText="Nova Escala"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
          :customFilters="customFilters"
          @filtered_ds="filtered_ds"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      customFilters: [
        {
          name: "start_date_y",
          type: "calendar",
          call: "filtered_ds",
        },
      ],
      start_date: "",
      table: "home_officeCrud",
      showMessageImport: false,
      project_typesCrud: {},
      pages: [],
      url: "",
      columns: ["id", "user_id", "status", "start_date_y", "home_office", "actions"],
      tableData: ["id", "user_id", "status", "start_date", "home_office"],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["user_id", "status", "home_office"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          user_id: "Usuário",
          start_date_y: "Data",
          status: "Status",
          home_office: "Expediente",
          actions: "Ações",
        },
        sortable: ["id", "user_id"],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status: [
            {
              id: 1,
              text: "Ativo",
            },
            {
              id: 0,
              text: "Inativo",
            },
          ],
          home_office: [
            {
              id: 0,
              text: "Presencial",
            },
            {
              id: 1,
              text: "Home Office",
            },
            {
              id: 2,
              text: "Férias",
            },
            {
              id: 3,
              text: "Folga",
            },
            {
              id: 4,
              text: "Atestado",
            },
          ],
        },
        templates: {
          status: function (h, row, index) {
            return row.status == 1
              ? "Ativo"
              : row.status == 0
              ? "Inativo"
              : "Inativo";
          },

          user_id: function (h, row, index) {
            return row.user ? row.user.name : "Indisponível";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.start_date != null &&
            this.$parent.$parent.$parent.start_date != ""
          ) {
            requestData.params.start_date =
              this.$parent.$parent.$parent.start_date;
          }

          requestData.params.query = "";
          requestData.params.with = ["user"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "home_offices/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    filtered_ds(date) {
      this.start_date = date;
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    importFile() {
      const self = this;
      let api = self.$store.state.api + "import-users";

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.showMessageImport = true;
          self.$refs.usersCrud.$refs.table.refresh();
        })
        .catch((error) => {
          self.$message(
            "Ops",
            "Houve um erro durante a importação da planilha, verifique a planilha e tente novamente.",
            "error"
          );
        });
    },
    query: function (query) {
      let columns = {
        id: "id",
        user_id: "user[name]",
        status: "status",
        home_office: "home_office",
      };
      let filters = "&orderByDesc=id&";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>