<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'reimbursementSolicitationsCrud'"
          crudName="Solicitações de Rembolsos"
          newText="Nova Solicitação de Rembolso"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="$store.state.user.perfil_id == 8 ? true : false"
          :enableView="false"
          :enableDownload="true"
          :dataForm="dataForm"
          :extra="extra"
          @view="view"
          :customFilters="customFilters"
          @filtered_ds="filtered_ds"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      customFilters: [
        {
          name: "date_f",
          type: "calendar",
          call: "filtered_ds",
        },
      ],
      date: "",
      table: "reimbursementSolicitationsTable",
      showMessageImport: false,
      reimbursementSolicitationsCrud: {},
      pages: [],
      url: "",
      columns: ["id", "user_id", "approve", "date_f", "actions"],
      tableData: [
        "id",
        "user_id",
        "date_f",
        "expense_description",
        "amount",
        "advance_amount",
        "total_expenses",
        "balance_to_receive",
        "deposit_bank",
        "branch",
        "checking_account",
        "bill_center_id",
        "approve",
        "date_approve",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["user_id", "approve"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          user_id: "Nome do Colaborador",
          approve: "Status",
          date_f: "Data da solicitação",
          actions: "Ações",
        },
        sortable: ["id"],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          approve: [
            {
              id: 0,
              text: "Aguardando Aprovação",
            },
            {
              id: 1,
              text: "Aprovado",
            },
            {
              id: 2,
              text: "Rejeitado",
            },
            {
              id: 3,
              text: "Aguardando Comprovante",
            },
          ],
        },
        templates: {
          approve: function (h, row, index) {
            switch (row.approve) {
              case 0:
                return (
                  <h6 class="text-center">
                    <span class="badge badge-primary">
                      Aguardando Aprovação
                    </span>
                  </h6>
                );
              case 1:
                return (
                  <h6 class="text-center">
                    <span class="badge badge-success">Aprovado</span>
                  </h6>
                );
              case 2:
                return (
                  <h6 class="text-center">
                    <span class="badge badge-danger">Rejeitado</span>
                  </h6>
                );
              case 3:
                return (
                  <h6 class="text-center">
                    <span class="badge badge-warning">
                      Aguardando Comprovante
                    </span>
                  </h6>
                );
              default:
            }
          },
          user_id: function (h, row, index) {
            return row.user ? row.user.name : "Sem Nome";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.date != null &&
            this.$parent.$parent.$parent.date != ""
          ) {
            requestData.params.date = this.$parent.$parent.$parent.date;
          }

          requestData.params.query = "";
          requestData.params.with = ["user"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "btn btn-crud view",
          title: "Visualizar",
          name: "view",
          icon: "fas fa-eye",
        },
      ],
      endPoint: "reimbursement_solicitations/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    filtered_ds(date) {
      this.date = date;
    },
    view(props, row, index) {
      const self = this;
      this.$router.push("/reimbursement_solicitations/view/" + props.row.id);
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    importFile() {
      const self = this;
      let api = self.$store.state.api + "import-users";

      let fd = self.makeFormData();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.showMessageImport = true;
          self.$refs.usersCrud.$refs.table.refresh();
        })
        .catch((error) => {
          self.$message(
            "Ops",
            "Houve um erro durante a importação da planilha, verifique a planilha e tente novamente.",
            "error"
          );
        });
    },
    query: function (query) {
      let columns = {
        id: "id",
        user_id: "user[name]",
        approve: "approve",
      };
      let filters = "&orderByDesc=id&";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    openInput() {
      document.getElementById("fileImport").click();
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>