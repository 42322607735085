<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Meu Perfil</div>
            </div>
            <div class="card-body">
              <div class="form-group d-flex justify-content-center">
                <b-avatar
                  size="150"
                  variant="light-primary"
                  badge
                  :src="$store.state.user.photo"
                  class="badge-minimal"
                  badge-variant="success"
                />
              </div>

              <div class="profile_user form-group">
                <h3 class="user_name_max">{{ users.name }}</h3>
                <hr class="hr" />
                <!-- <p style="text-align: center">{{ users.email }}</p> -->
                <br />
                <br />
              </div>
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <!-- Campos Fake para Distrair o Navegador com autocomplete -->
                    <input
                      id="username"
                      style="display: none"
                      type="text"
                      name="fakeusernameremembered"
                    />
                    <input
                      id="password"
                      style="display: none"
                      type="password"
                      name="fakepasswordremembered"
                    />
                    <!-- Fim dos campos fake -->

                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="userName">
                          <span class="required">*</span> Nome:
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="users.name"
                          placeholder=""
                          autocomplete="new-password"
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="inputlogin4">
                          <span class="required">*</span> E-mail:
                        </label>
                        <input
                          disabled
                          autocomplete="off"
                          class="form-control"
                          id="userlogin"
                          placeholder=""
                          type="text"
                          v-model="users.email"
                        />
                      </div>
                    </div>
                    <!-- Seção de Dados Bancários -->
                    <div class="form-group mt-3">
                      <h4>Informações de Reembolso:</h4>
                      <hr />
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label>Banco</label>
                        <input
                          v-model="users.deposit_bank"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label>Agência</label>
                        <input
                          v-model="users.branch"
                          type="text"
                          class="form-control"
                          v-mask="'9999'"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label>Conta Corrente</label>
                        <input
                          v-model="users.checking_account"
                          type="text"
                          class="form-control"
                          v-mask="'99999999-9'"
                        />
                      </div>
                    </div>
                    <!-- Seção Chave Pix -->
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="pixKey">Tipo de Chave Pix:</label>
                        <v-select
                          v-model="users.pix_key"
                          :options="pixOptions"
                          placeholder="Selecione a chave"
                          id="pixKey"
                          class="custom-v-select"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-6" v-if="users.pix_key">
                        <label for="pixValue">Valor da Chave Pix:</label>
                        <input
                          v-model="users.pix_value"
                          :key="users.pix_key"
                          :type="users.pix_key === 'E-Mail' ? 'email' : 'text'"
                          class="form-control"
                          id="pixValue"
                          placeholder="Digite sua chave pix"
                          v-mask="pixMask"
                        />
                      </div>
                    </div>
                    <div class="form-group mt-4">
                      <hr />
                      <div>
                        <label
                          href="#"
                          class="pointer"
                          data-target="#pwdModal"
                          data-toggle="modal"
                        >
                          <a class="btn btn-put-pass">
                            Deseja alterar a senha?
                            <i class="fa fa-key"></i>
                          </a>
                        </label>
                      </div>

                      <div
                        id="pwdModal"
                        v-on:keyup="keymonitor"
                        class="modal fade"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <label>Digite a nova password</label>
                            </div>
                            <div class="modal-body">
                              <div class="col-md-12">
                                <div class="panel panel-default">
                                  <div class="panel-body">
                                    <div class="text-center">
                                      <div class="panel-body">
                                        <fieldset>
                                          <div class="form-group">
                                            <div class="row">
                                              <div class="col-md-10">
                                                <label for="userPassword">
                                                  <span class="required">*</span>
                                                  Nova password:
                                                </label>
                                                <input
                                                  :type="passwordFieldType"
                                                  v-model="users.new_password"
                                                  class="form-control"
                                                />
                                              </div>
                                              <div class="col-md-1 mt-2 mr-2">
                                                <button
                                                  type="button"
                                                  class="btn btn-edit"
                                                  @click="togglePasswordVisibility"
                                                >
                                                  <i
                                                    :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                                                  ></i>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <div class="row">
                                              <div class="col-md-10">
                                                <label for="userPasswordConfirmation">
                                                  <span class="required">*</span>
                                                  Confirmar password:
                                                </label>
                                                <input
                                                  :type="passwordFieldTypeConfirm"
                                                  v-on:keyup="keymonitor"
                                                  class="form-control"
                                                  id="userPasswordConfirmation"
                                                  v-model="users.password_confirmation"
                                                  placeholder
                                                  autocomplete="false"
                                                  minlength="6"
                                                  maxlength="12"
                                                />
                                              </div>
                                              <div class="col-md-1 mt-2 mr-2">
                                                <button
                                                  type="button"
                                                  class="btn btn-edit"
                                                  @click="togglePasswordVisibilityConfirm"
                                                >
                                                  <i
                                                    :class="showPasswordConfirm ? 'fas fa-eye-slash' : 'fas fa-eye'"
                                                  ></i>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                          <div v-if="comparePassword()" class="left">
                                            <label>
                                              <div class="alert alert-danger col-md-12" role="alert">
                                                <span class="comparePassword">
                                                  As senhas não podem ser diferentes!
                                                </span>
                                              </div>
                                            </label>
                                          </div>
                                        </fieldset>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <div class="col-md-12">
                                <div class="block text-right">
                                  <a
                                    id="exitModal"
                                    @click="clearModal()"
                                    data-dismiss="modal"
                                    class="btn btn-back"
                                  >
                                    <i class="fa fa-arrow-left"></i> Cancelar
                                  </a>
                                  <a data-dismiss="modal" class="btn btn-add ml-2">
                                    Continuar
                                    <i class="fa fa-save"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div
                      class="form-group mt-3"
                      v-if="users.photo != null || users.photo != null"
                    >
                      <label for="userPic">Foto do Perfil</label>
                      <input
                        type="file"
                        class="form-control"
                        id="userPic"
                        accept="image/*"
                        name="userPic"
                        @change="uploadImage"
                        placeholder
                      />
                    </div>
                    <span class="required_fields">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/users" class="btn btn-back mr-1">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BModal,
} from "bootstrap-vue";
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      companies: [],
      departments: [],
      change_password: true,
      users: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        new_password: "",
        deposit_bank: "",
        branch: "",
        checking_account: "",
        pix_key: "", // Tipo da chave pix (CPF, CNPJ, Telefone, E-Mail)
        pix_value: "" // Valor da chave pix
      },
      pixOptions: ["CPF", "CNPJ", "Telefone", "E-Mail"],
      showPassword: false,
      showPasswordConfirm: false,
      companies: {},
      departments: {},
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? "text" : "password";
    },
    passwordFieldTypeConfirm() {
      return this.showPasswordConfirm ? "text" : "password";
    },
    pixMask() {
      switch (this.users.pix_key) {
        case "CPF":
          return "999.999.999-99";
        case "CNPJ":
          return "99.999.999/9999-99";
        case "Telefone":
          return "(99) 99999-9999";
        default:
          return ""; // Para E-Mail ou se não houver seleção, nenhuma máscara
      }
    }
  },
  methods: {
    makeFormData() {
      const self = this;
      let fd = new FormData();
      let photo = document.getElementById("userPic");
      fd.append("name", self.users.name);
      fd.append("email", self.users.email);
      fd.append("password", self.users.new_password);
      fd.append("password_confirmation", self.users.password_confirmation);
      fd.append("deposit_bank", self.users.deposit_bank ? self.users.deposit_bank : "");
      fd.append("branch", self.users.branch ? self.users.branch : "");
      fd.append("checking_account", self.users.checking_account ? self.users.checking_account : "");
      fd.append("pix_value", self.users.pix_value ? self.users.pix_value : "");
      fd.append("pix_key", self.users.pix_key ? self.users.pix_key : "");
      fd.append("photo", photo.files[0] ? photo.files[0] : "");
      if (self.users.id) {
        fd.append("id", self.users.id);
        fd.append("_method", "PUT");
      }
      return fd;
    },
    save() {
      const self = this;
      let api = self.$store.state.api + "users";
      let fd = self.makeFormData();
      if (self.users.id) {
        api += "/" + self.users.id;
      }
      axios
        .post(api, fd)
        .then((response) => {
          self.$message("Sucesso", `Informações guardadas com sucesso`, "success");
          this.$router.push("/");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    comparePassword() {
      if (this.users.new_password != this.users.password_confirmation || null)
        return [];
    },
    keymonitor() {
      if (event.getModifierState && event.getModifierState("CapsLock")) {
        this.activatedCaps = true;
      } else {
        this.activatedCaps = false;
      }
    },
    buscar() {
      var self = this;
      self.naoLocalizado = false;
      if (/^[0-9]{5}-[0-9]{3}$/.test(this.users.cep)) {
        $.getJSON("https://viacep.com.br/ws/" + this.users.cep + "/json/", function (endereco) {
          if (endereco.erro) {
            self.endereco = {};
            $("#inputNumero").focus();
            self.naoLocalizado = true;
            return;
          }
          self.endereco = endereco;
          self.users.street = self.endereco.logradouro;
          self.users.neighborhood = self.endereco.bairro;
          self.users.state = self.endereco.uf;
          self.users.city = self.endereco.localusuario_idade;
          $("#inputNumero").focus();
        });
      }
    },
    clearModal() {
      this.users.password_confirmation = "";
      this.users.new_password = "";
    },
    getUsers(id) {
      const self = this;
      const api = self.$store.state.api + "me";
      axios
        .post(api)
        .then((response) => {
          self.users = response.data;
          self.users.password_confirmation = "";
          self.users.password = "";
          self.users.password_confirmation = "";
          self.users.new_password = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    togglePasswordVisibilityConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm;
    },
  },
  mounted() {
    const self = this;
    self.getUsers();
  },
  components: {
    BaseCrud,
    BAvatar,
    vSelect,
  },
};
</script>

<style scoped>
.left {
  text-align: left;
}
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  height: 200px;
  object-fit: cover;
}
.modal-footer {
  border-top: 0px;
}
.pointer {
  cursor: pointer;
}
.caps {
  color: black;
  font: bold;
  text-align: right;
  font-size: 20px;
}
.required {
  color: red !important;
}
.btn-put-prof:hover,
.btn-put-prof[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}
.profile_user {
  text-align: center;
}
</style>
